import styled, { css } from "styled-components";
import { colorTheme } from "../../../styles/defaultTheme";

const textVariant = (variant, inverted) => {
  switch (variant) {
    case "title":
      return css`
        color: ${(props) =>
          props.inverted ? "white" : `${colorTheme.colors.neutral.black}`};
        font-size: 48px;
        line-height: 135%;
        text-align: center;
        font-weight: 500;

        &.gradient {
          background: radial-gradient(
            45% 100% at 50% 50%,
            #fff 30%,
            hsla(0, 0%, 100%, 0.4) 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          line-height: 1;
          letter-spacing: -0.02em;
          font-weight: 500;
          -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
          max-width: 450px;
          margin: auto;
        }

        @media only screen and (max-width: 1024px) {
          font-size: 32px;
          &.xl-text {
            font-size: 40px !important;
            font-weight: 500;
          }
        }

        &.xl-text {
          font-size: 72px;
          font-weight: 600;
        }

        &.oland-text {
          color: white;
          font-weight: 600;
          font-size: 86px;
          padding-bottom: 32px;
          text-align: center;
          line-height: 120%;

          @media only screen and (max-width: 772px) {
            padding-bottom: 18px;
          }

          span {
            font-size: 40px;

            &.oland-signup-text {
              font-size: 24px;
              font-weight: 500;
              line-height: 100%;
            }
          }

          @media only screen and (max-width: 1024px) {
            font-size: 32px;

            span {
              font-size: 24px;

              &.oland-signup-text {
                font-size: 18px;
              }
            }
          }
        }
      `;
    case "cardtitle":
      return css`
        color: ${colorTheme.colors.neutral.black};
        font-size: 42px;
        line-height: 135%;
        text-align: center;

        @media only screen and (max-width: 1080px) {
          font-size: 32px;
        }

        @media only screen and (max-width: 776px) {
          font-size: 24px;
        }
      `;
    case "cardsubtitle":
      return css`
        color: ${colorTheme.colors.neutral.black};
        font-size: 24px;
        line-height: 135%;
        text-align: center;
        font-weight: 400;

        @media only screen and (max-width: 1080px) {
          font-size: 18px;
        }

        @media only screen and (max-width: 776px) {
          font-size: 16px;
        }
      `;
    case "carddescription":
      return css`
        color: ${colorTheme.colors.neutral.black};
        font-size: 20px;
        line-height: 135%;

        text-align: center;
        font-weight: 400;
      `;
    case "subtitle":
      return css`
        font-size: 32px;
        line-height: 135%;
        font-weight: 400;
        text-align: center;

        .bigger {
          font-size: 48px;
          font-weight: 500;
          line-height: 200%;
        }

        @media only screen and (max-width: 1024px) {
          .bigger {
            font-size: 24px;
          }
          font-size: 16px;
        }
      `;
    case "body":
      return css`
        color: ${colorTheme.colors.neutral.black};
        font-size: 24px;
        line-height: 135%;
        font-weight: 400;
        opacity: 1;

        @media only screen and (max-width: 1024px) {
          font-size: 16px;
        }
      `;
    case "cardbody":
      return css`
        font-size: 24px;
        line-height: 135%;
        font-weight: 400;

        &.xl {
          font-size: 36px;
          font-weight: 400;

          @media only screen and (max-width: 1024px) {
            font-size: 24px;
          }
        }

        .highlight {
          opacity: 100% !important;
          font-weight: 400;
        }
        .color {
          opacity: 1 !important;
          background-image: linear-gradient(
            to right,
            #f8ab5e 0,
            #f36961 20%,
            #a176c8 40%,
            #759beb 60%,
            #65beb3 80%,
            #70db96 100%
          ) !important;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: 400;
        }

        @media only screen and (max-width: 1024px) {
          font-size: 16px;
        }
      `;
    case "cardcolor":
      return css`
        font-size: 24px;
        line-height: 135%;
        font-weight: 400;
        background-image: linear-gradient(
          to right,
          #f8ab5e 0,
          #f36961 20%,
          #a176c8 40%,
          #759beb 60%,
          #65beb3 80%,
          #70db96 100%
        ) !important;
        -webkit-background-clip: text;

        .highlight {
          opacity: 100% !important;
          font-weight: 400;
        }
        .color {
          opacity: 1 !important;
          -webkit-text-fill-color: transparent;
          font-weight: 500;
        }

        @media only screen and (max-width: 1024px) {
          font-size: 16px;
        }
      `;
    case "cardcaption":
      return css`
        font-size: 24px;
        font-weight: 500;
        line-height: 135%;

        &.xl {
          font-size: 72px;
          margin-bottom: 16px;
          margin-top: 0px;
        }

        .chart-text {
          font-size: 20px;
        }

        &.color {
          opacity: 1 !important;
          background-image: linear-gradient(
            to right,
            #f8ab5e 0,
            #f36961 20%,
            #a176c8 40%,
            #759beb 60%,
            #65beb3 80%,
            #70db96 100%
          ) !important;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: 700;
        }

        &.large {
          font-size: 40px;
        }
        &.larger {
          font-size: 48px;
        }
        @media only screen and (max-width: 1024px) {
          font-size: 24px;

          &.countdown-caption {
            font-size: 16px;
            margin: auto;
          }

          &.xl {
            font-size: 36px;
          }

          &.larger {
            font-size: 32px;
          }

          .dull {
            font-size: 12px !important;
            line-hight: 75% !important;
          }

          .chart-text {
            font-size: 12px !important;
            line-hight: 75% !important;
          }
        }
      `;
    case "banner":
      return css`
        color: ${(props) =>
          props.inverted ? "white" : `${colorTheme.colors.neutral.black}`};
        font-size: 12px;
        line-height: 135%;
        font-weight: 400;

        .link-span {
          color: rgba(36, 138, 255, 1) !important;
          cursor: pointer;
          :hover {
            color: rgba(33, 124, 229, 1) !important;
            text-decoration: underline !important;
          }
        }
      `;
    case "navigation":
      return css`
        color: white;
        opacity: 0.8;
        font-size: 14px;
        line-height: 135%;
        font-weight: 400;
        :hover {
          color: white;
          opacity: 1;
        }
      `;
    case "link":
      return css`
        color: rgba(36, 138, 255, 1);
        opacity: 1;
        font-size: 18px;
        line-height: 135%;
        font-weight: 400;
        cursor: pointer;
        :hover {
          color: rgba(33, 124, 229, 1);
          text-decoration: underline;
        }

        &.small {
          font-size: 14px;
        }
      `;
    case "mobilenav":
      return css`
        color: white;
        opacity: 1;
        font-size: 24px;
        line-height: 135%;
        font-weight: 400;
        :hover {
          color: rgba(36, 138, 255, 1);
          text-decoration: underline;
        }
      `;
    case "langlink":
      return css`
        color: ${colorTheme.colors.neutral.black};
        opacity: 1;
        font-size: 18px;
        line-height: 135%;
        font-weight: 400;
        :hover {
          color: ${colorTheme.colors.text.grey};
          opacity: 0.8;
          text-decoration: underline;
        }
      `;
    case "footertitle":
      return css`
        color: ${colorTheme.colors.neutral.black};
        opacity: 1;
        font-size: 24px;
        line-height: 135%;
        font-weight: 400;
        :hover {
          opacity: 0.8;
          text-decoration: underline;
        }
      `;
    case "footerlink":
      return css`
        font-size: 18px;
        line-height: 135%;
        font-weight: 400;
        :hover {
          opacity: 1;
          color: rgba(36, 138, 255, 1);
        }

        @media only screen and (max-width: 1024px) {
          font-size: 10px;
        }
      `;
    case "socialbrand":
      return css`
        color: ${colorTheme.colors.neutral.black};
        font-size: 18px;
        line-height: 100%;
        font-weight: 500;
      `;
    case "socialdescription":
      return css`
        color: rgba(36, 138, 255, 1);
        font-size: 18px;
        line-height: 100%;
        font-weight: 400;
      `;
    case "colortext":
      return css`
        font-size: 72px;
        font-weight: 600;
        line-height: 100%;
        background: linear-gradient(
          to right,
          rgb(248, 171, 93) 0,
          rgb(242, 104, 97) 20%,
          rgb(161, 118, 200) 40%,
          rgb(117, 155, 235) 60%,
          rgb(101, 190, 179) 80%,
          rgb(112, 219, 150) 100%
        );
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        @media only screen and (max-width: 1024px) {
          font-size: 32px;
        }

        &.smaller {
          font-size: 64px;
        }
      `;

    default:
      return css`
        color: ${colorTheme.colors.neutral.black};
        font-size: 18px;
        line-height: 135%;
        font-weight: 400;
      `;
  }
};

const Text = styled.h1`
  ${(props) => textVariant(props.variant)};
`;

export default Text;
