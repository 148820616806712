import React from "react";
import styled from "styled-components";
import { devices } from "../../styles/screenSizes";

export default function Wrapper({ fullHeight, children, limited }) {
  return (
    <Container limited={limited} fullHeight={fullHeight}>
      {children}
    </Container>
  );
}

const Container = styled.div`
  height: ${(props) => (props.fullHeight ? "100%" : "auto")};
  margin: auto;
  max-width: ${(props) => (props.limited ? "1024px !important" : "")};

  @media only screen and ${devices.desktop} {
    max-width: 1440px;
  }
  @media only screen and ${devices.laptopL} {
    max-width: 1280px;
  }
  @media only screen and ${devices.laptop} {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }
  @media only screen and ${devices.mobileL} {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  @media only screen and ${devices.mobileM} {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  @media only screen and ${devices.mobileS} {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  @media only screen and (max-width: 1024px) {
    .flex-grid {
      &.orouter {
        flex-wrap: wrap !important;
      }
    }
  }

  @media only screen and (max-width: 772px) {
    .flex-grid {
      flex-wrap: wrap !important;

      .sub-button {
        width: 100%;
      }
    }
  }

  table {
    &.spec-table {
      border: none;
      color: white;
      margin: 40px auto;
      width: 100%;
      max-width: 1000px;
      .bold {
        font-weight: 500;
        font-size: 24px;

        @media only screen and (max-width: 1024px) {
          font-size: 16px;
        }
      }

      td {
        border: none;
        padding: 16px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.35);
        font-size: 24px;
        line-height: 135%;

        @media only screen and (max-width: 1024px) {
          font-size: 16px;
        }
      }
    }
  }
`;
