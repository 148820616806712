export const menuData = [
  // {
  //   title: "Overline",
  //   icon: "",
  //   link: "/multichain",
  //   external: false,
  //   target: "",
  //   ref: "noopener noreferrer",
  // },
  {
    title: "ōRouter",
    icon: "",
    link: "/",
    external: false,
    target: "",
    ref: "noopener noreferrer",
  },

  {
    title: "ōLand",
    icon: "",
    link: "/oland",
    external: false,
    target: "",
    ref: "noopener noreferrer",
  },
  // {
  //   title: "Apps",
  //   icon: "",
  //   link: "/applications",
  //   external: false,
  //   target: "",
  //   ref: "noopener noreferrer",
  // },
  {
    title: "ōMap",
    icon: "",
    link: "/omap",
    external: false,
    target: "",
    ref: "noopener noreferrer",
  },
  {
    title: "Community",
    icon: "",
    link: "https://twitter.com/overlinenetwork",
    external: true,
    target: "_blank",
    ref: "noopener noreferrer",
  },
];

export const downloadData = [
  {
    title: "Mac",
    icon: "/images/logos/os/mac-white.png",
    link: "https://overline.network/Overline.dmg",
  },
  {
    title: "Windows",
    icon: "/images/logos/os/windows-white.png",
    link: "https://overline.network/Overline.exe",
  },
  {
    title: "Linux",
    icon: "/images/logos/os/linux-white.png",
    link: "https://overline.network/Overline.AppImage",
  },
];

export const localeData = [
  {
    locale: "en",
    title: "English",
  },
  {
    locale: "ru",
    title: "Русский",
  },
  {
    locale: "cn",
    title: "汉语",
  },
  // {
  //   locale: "ko",
  //   title: "汉语",
  // },
  // {
  //   locale: "ja",
  //   title: "日本語",
  // },
  // {
  //   locale: "es",
  //   title: "Español",
  // },
  // {
  //   locale: "tr",
  //   title: "Türkçe",
  // },
  // {
  //   locale: "ar",
  //   title: "العربية",
  // },
  // {
  //   locale: "vi",
  //   title: "Tiếng Việt",
  // },
  // {
  //   locale: "hi",
  //   title: "हिन्दी",
  // },
  // {
  //   locale: "id",
  //   title: "Bahasa Indonesia",
  // },
];

// {
//   title: "Documentation",
//   icon: "",
//   link: "https://docs.overline.network",
//   external: true,
// },
// {
//   title: "Explorer",
//   icon: "",
//   link: "https://explorer.overline.network",
//   external: true,
// },
// { title: "Careers", icon: "", link: "/careers", external: false },
