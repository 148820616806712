import { useRouter } from "next/router";
import { getAffiliateTokenLocalStorage } from "../utils";

const useAffiliateUrl = () => {

  // try to extract from URL, thats the priority 
  const router = useRouter();
  const { a, affiliateToken, key } = router.query;

  if (affiliateToken) {
    return `?key=${affiliateToken}`;
  }

  if (a) {
    return `?key=${a}`;
  }

  if (key) {
    return `?key=${key}`;
  }

  // try to extract from 
  const affilToken = getAffiliateTokenLocalStorage()
  if (affilToken) {
    return `?key=${affilToken}`;
  }

  return ''
};

export default useAffiliateUrl;
