export const colorTheme = {
  colors: {
    background: "#f5f5f7",
    text: {
      black: "rgba(0, 0, 0, 1)",
      white: "rgba(255, 255, 255, 1)",
      grey: "#57627b",
      darkerGrey: "rgba(84, 93, 105, 1)",
      error: "rgba(218, 20, 20, 1)",
      success: "rgba(35, 167, 87, 1)",
      blue: "rgba(36,138,255,1)",
      darkBlue: "rgba(33, 124, 229, 1)",
      ape: "rgba(202,137,96,1)",
    },
    primary: {
      primary100: "rgba(36,138,255,1)",
      primary90: "rgba(36,138,255,0.9)",
      primary80: "rgba(36,138,255,0.8)",
      primary70: "rgba(36,138,255,0.7)",
      primary60: "rgba(36,138,255,0.6)",
      primary50: "rgba(36,138,255,0.5)",
      primary40: "rgba(36,138,255,0.4)",
      primary30: "rgba(36,138,255,0.3)",
      primary20: "rgba(36,138,255,0.2)",
      primary10: "rgba(36,138,255,0.1)",
    },
    tertiary: {
      teriary100: "rgba(246, 192, 84, 1)",
      teriary90: "rgba(246, 192, 84, 0.9)",
      teriary80: "rgba(246, 192, 84, 0.8)",
      teriary70: "rgba(246, 192, 84, 0.7)",
      teriary60: "rgba(246, 192, 84, 0.6)",
      teriary50: "rgba(246, 192, 84, 0.5)",
      teriary40: "rgba(246, 192, 84, 0.4)",
      teriary30: "rgba(246, 192, 84, 0.3)",
      teriary20: "rgba(246, 192, 84, 0.2)",
      teriary10: "rgba(246, 192, 84, 0.1)",
    },
    neutral: {
      black: `#0b121e`,
      white: `rgba(255,255,255,1)`,
      neutral1: `#1d273d`,
      neutral2: `#303b50`,
      neutral3: `#57627b`,
      neutral4: `#8f9bb3`,
      neutral5: `#adb8cf`,
      neutral6: `#c5cee0`,
      neutral7: `#dee6f1`,
      neutral8: `#eef3fb`,
      neutral9: `#E3E8EF`,
    },
    accent: {
      accent1: `rgba(246,183,68,1)`,
      accent2: `rgba(101,99,50,1)`,
      accent3: `rgba(202,137,96,1)`,
      accent4: `rgba(42,43,71,1)`,
      accent5: `rgba(143,183,214,1)`,
      accent6: `rgba(161,51,10,1)`,
    },
    error: "rgba(218, 20, 20, 1)",
    errorBackground: "rgba(254, 239, 239, 1)",
    button: {
      primary: {
        background: "rgba(0, 0, 0, 1)",
      },
    },
    input: {
      error: "rgba(218, 20, 20, 1)",
      placeholder: "rgba(218, 222, 227, 1)",
      border: "rgba(218, 222, 227, 1)",
      borderFocused: "rgba(0, 169, 188, 1)",
      borderError: "rgba(218, 20, 20, 1)",
    },
    tag: {
      background: "rgba(242, 244, 246, 1)",
    },
    slider: {
      dot: {
        inactiveBackground: "rgba(226, 226, 226, 1)",
        activeDot: "rgba(51, 51, 51, 1)",
      },
    },
    tabBar: {
      background: "rgba(255,255,255,1)",
      border: "rgba(127, 143, 169, 0.2)",
    },
    list: {
      borderTop: "rgba(204, 210, 221, 1)",
    },
  },
};
