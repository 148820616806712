import React from "react";
import styled from "styled-components";
import { useTranslation } from "next-i18next";
import { menuData } from "../../../../data/menuData";
import Text from "../../../ui/Text";
import Link from "next/link";
import Button from "../../../ui/Button";
import useAffiliateUrl from "../../../../hooks/useAffiliateUrl";
import { colorTheme } from "../../../../styles/defaultTheme";

export default function MobileMenu(props) {
  const { mobileMenuOpen } = props;
  const { i18n } = useTranslation();
  const affiliateToken = useAffiliateUrl();

  const NavButton = React.forwardRef(({ onClick, href, title }, ref) => {
    return (
      <a
        href={href}
        title={title}
        onClick={onClick}
        variant="mobilenav"
        ref={ref}
      >
        <Text variant="mobilenav">{title}</Text>
      </a>
    );
  });
  NavButton.displayName = "NavButton";

  return (
    <MenuWrapper mobileMenuOpen={mobileMenuOpen} inverted={!props.inverted}>
      {menuData.map((item, index) => (
        <div style={{ cursor: "pointer" }} key={index}>
          <a
            href={`${item.link}${affiliateToken}`}
            onClick={() => props.toggle()}
            // ref={item.ref}
            // target={item.target}
          >
            <NavButton title={item.title} />
          </a>
        </div>
      ))}
      <div style={{ width: 150, margin: "16px auto" }} className="swap-button">
        {/*// TODO: better solution env, or take hostname?*/}
        <Button
          white={true}
          inverted={!props.inverted}
          buttonText="Swap"
          onClick={() =>
            (window.location.href = "https://app.overline.network/#/swap")
          }
        />
      </div>
    </MenuWrapper>
  );
}

const MenuWrapper = styled.div`
  position: absolute;
  top: 80px;
  width: 100%;
  right: 0;
  left: 0;
  height: 100vh;
  background-color: ${(props) =>
    props.inverted ? colorTheme.colors.neutral.black : "black"};
  transition: background-color 0.5s cubic-bezier(0.28, 0.11, 0.32, 1);
  transition-property: background-color, -webkit-backdrop-filter;
  transition-property: background-color, backdrop-filter;
  transition-property: background-color, backdrop-filter,
    -webkit-backdrop-filter;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.15);
  padding: 12px;
  opacity: ${(props) => (props.mobileMenuOpen ? 1 : 0)};
  text-align: center;
  transition: 0.3s ease-in-out;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 8;
  visibility: ${(props) => (props.mobileMenuOpen ? "visible" : "hidden")};
  transform: ${(props) =>
    props.mobileMenuOpen ? "translateY(0)" : "translateY(50px)"};

  .nav-item {
    cursor: pointer;
  }

  .swap-button {
    @media only screen and (max-width: 883px) {
      display: none;
    }
  }
`;
