import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Wrapper from "../../ui/Wrapper";
import { menuData } from "../../../data/menuData";
import { useTranslation } from "next-i18next";
import Text from "../../ui/Text";
import LanguageTooltip from "./components/LanguageTooltip";
import Link from "next/link";
import Banner from "../../ui/Banner";
import Button from "../../ui/Button";
import { Squash as Hamburger } from "hamburger-react";
import MobileMenu from "./components/MobileMenu";
import useAffiliateUrl from "../../../hooks/useAffiliateUrl";
import { colorTheme } from "../../../styles/defaultTheme";
import Image from "next/image";

export default function Navbar({
  inverted,
  white,
  bannerText,
  bannerLink,
  bannerBold,
  navy,
  banner,
}) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [twitterFollowers, setTwitterFollowers] = useState("45k");

  const affiliateToken = useAffiliateUrl();

  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [scrollingDown, setScrollingDown] = useState(false);

  // useEffect(() => {
  //   (async () => {
  //     const result = await axios.get(
  //       `https://cdn.syndication.twimg.com/widgets/followbutton/info.json?screen_names=overlinenetwork`
  //     );
  //     const data = JSON.parse(result);
  //     console.log(data);
  //     setTwitterFollowers(data);
  //   })();
  // }, []);

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updatescrollingDown = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollingDown(scrollY > lastScrollY ? true : false);
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updatescrollingDown);
        ticking = true;
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollingDown]);

  // FOR CRAIG LATER:
  // function renderBasedOnLocale() {
  //   const localePreferences = nagivator.languages;
  //   console.log("User language preferences", localePreferences);

  //   if (localePreferences === "CHOICE") {
  //     console.log("Language detected was", localePreferences);
  //   }
  // }

  // function renderLanguage() {
  //   if (i18n.resolvedLanguage === "en") {
  //     return "🇺🇸 EN";
  //   }
  //   if (i18n.resolvedLanguage === "ru") {
  //     return "🇷🇺 RU";
  //   }
  //   if (i18n.resolvedLanguage === "cn") {
  //     return "🇨🇳 CN";
  //   }
  // }

  function renderMobileMenu() {
    return (
      <ContentContainer className="mobile-menu">
        <Link
          href={`/${affiliateToken}`}
          style={{
            height: "100%",
            alignItems: "center",
          }}
          passHref
        >
          <div style={{ cursor: "pointer", width: 50 }}>
            <svg
              height="20"
              viewBox="0 0 113 152"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M112.5 19.3L0 18.8V0L112.5 0.5V19.3ZM56.7 38C88.1 38 112.9 63.3 112.9 95.2C113.5 126.2 88.7 152 56.7 152C24.7 152 0.5 126.2 0.5 95.3C0.5 63.3 25.3 38 56.7 38ZM56.7 131.7C76.5 131.7 92.7 115.5 92.7 95.2C92.7 74.9 77 58.2 56.7 58.2C36.4 58.2 20.7 74.4 20.7 95.2C20.7 116 36.5 131.7 56.7 131.7Z"
                fill={!inverted ? "black" : "white"}
              />
            </svg>
          </div>
        </Link>
        <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
          <div style={{ width: 100 }}>
            {/*// TODO: better solution env, or take hostname?*/}
            <Button
              nav={true}
              style={{ width: 100 }}
              buttonText="Sign In"
              onClick={() =>
                (window.location.href = `${process.env.NEXT_PUBLIC_WAITLIST_APP_BASE_URL}/`)
              }
            />
          </div>
          <div style={{ width: 50 }}>
            <Hamburger
              color={!inverted ? "black" : "white"}
              toggled={mobileMenuOpen}
              toggle={setMobileMenuOpen}
              size={20}
            />
          </div>
        </div>
        <MobileMenu
          mobileMenuOpen={mobileMenuOpen}
          toggle={setMobileMenuOpen}
          inverted={inverted}
        />
      </ContentContainer>
    );
  }

  const socialData = [
    {
      title: "Twitter",
      url: "https://twitter.com/overlinenetwork",
      img: !inverted
        ? "/assets/svg/social/Twitter.svg"
        : "/assets/svg/social/Twitter-white.svg",
    },
    {
      title: "Discord",
      url: "https://overline.network",
      img: !inverted
        ? "/assets/svg/social/Discord.svg"
        : "/assets/svg/social/Discord-white.svg",
    },
    {
      title: "Facebook",
      url: "https://www.facebook.com/overlinenetwork/",
      img: !inverted
        ? "/assets/svg/social/Facebook.svg"
        : "/assets/svg/social/Facebook-white.svg",
    },
    {
      title: "Instagram",
      url: "https://www.instagram.com/overline.network",
      img: !inverted
        ? "/assets/svg/social/Instagram.svg"
        : "/assets/svg/social/Instagram-white.svg",
    },
    {
      title: "TikTok",
      url: "https://www.tiktok.com/@overlinenetwork",
      img: !inverted
        ? "/assets/svg/social/TikTok.svg"
        : "/assets/svg/social/TikTok-white.svg",
    },
    // {
    //   title: "Pinterest",
    //   url: "https://twitter.com/overlinenetwork",
    //   img: "/assets/svg/social/Pinterest.svg",
    // },
    {
      title: "YouTube",
      url: "https://www.youtube.com/c/OverlineNetwork",
      img: !inverted
        ? "/assets/svg/social/Youtube.svg"
        : "/assets/svg/social/Youtube-white.svg",
    },
  ];

  return (
    <>
      <Container inverted={inverted} scrollingDown={scrollingDown} navy={navy}>
        <SocialBannerSC inverted={inverted}>
          <NavWrapper limited={true} style={{ width: "100%" }}>
            <div className="social-banner">
              <div className="left">En</div>
              <div className="right">
                {socialData.map((item, index) => (
                  <SocialButtonSC
                    key={index}
                    href={item.url}
                    alt="Social Button"
                    color={item.color}
                    inverted={inverted}
                  >
                    <img src={item.img} alt={item.title} />
                  </SocialButtonSC>
                ))}
                {/* <a
                  href="https://discord.gg/overline"
                  className="social-button discord"
                  data-show-count="true"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/svg/social/Discord.svg" alt="twitter" />
                  <span className="social-text">Discord</span>
                </a>
                <a
                  href="https://twitter.com/overlinenetwork"
                  className="social-button twitter"
                  data-show-count="true"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/svg/social/Twitter.svg" alt="twitter" />
                  <span className="social-text">Twitter</span>
                </a> */}
              </div>
            </div>
          </NavWrapper>
        </SocialBannerSC>

        <NavWrapper limited={true} fullHeight={false}>
          {renderMobileMenu()}
          <ContentContainer className="main-menu">
            <Link
              href={`/${affiliateToken}`}
              style={{
                height: "100%",
                alignItems: "center",
              }}
              passHref
            >
              <div style={{ cursor: "pointer" }}>
                <svg
                  height="20"
                  viewBox="0 0 113 152"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M112.5 19.3L0 18.8V0L112.5 0.5V19.3ZM56.7 38C88.1 38 112.9 63.3 112.9 95.2C113.5 126.2 88.7 152 56.7 152C24.7 152 0.5 126.2 0.5 95.3C0.5 63.3 25.3 38 56.7 38ZM56.7 131.7C76.5 131.7 92.7 115.5 92.7 95.2C92.7 74.9 77 58.2 56.7 58.2C36.4 58.2 20.7 74.4 20.7 95.2C20.7 116 36.5 131.7 56.7 131.7Z"
                    fill={!inverted ? "black" : "white"}
                  />
                </svg>
              </div>
            </Link>
            {menuData.map((item, index) => (
              <Link
                key={index}
                href={`${item.link}${affiliateToken}`}
                alt={item.alt}
                passHref
                // ref={item.ref}
                // target={item.target}
              >
                <Text
                  variant="navigation"
                  style={{
                    cursor: "pointer",
                    color: !inverted ? "black" : "white",
                  }}
                >
                  {item.title}
                </Text>
              </Link>
            ))}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: 24,
              }}
            >
              <div style={{ width: 100 }}>
                {/*// TODO: better solution env, or take hostname?*/}
                <Button
                  nav={true}
                  style={{ width: 100 }}
                  buttonText="Sign In"
                  onClick={() =>
                    (window.location.href = `${process.env.NEXT_PUBLIC_WAITLIST_APP_BASE_URL}`)
                  }
                />
              </div>
            </div>
          </ContentContainer>
        </NavWrapper>
      </Container>
      {banner && (
        <Banner
          variant="banner"
          bannerText={bannerText}
          bannerBold={bannerBold}
          inverted={inverted}
          white={white}
          bannerLink={bannerLink}
          navy={navy}
        />
      )}
    </>
  );
}

const Container = styled.div`
  background: ${(props) =>
    !props.inverted
      ? "rgba(255,255,255,0.85)"
      : props.navy
      ? "#090d14"
      : "rgba(0,0,0,1)"};
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  height: 80px;
  transition: all 0.4s ease-in-out;
  top: ${(props) => (!props.scrollingDown ? "0px" : "-30px")};
  position: fixed;
  width: 100%;
  z-index: 999;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  @media only screen and (max-width: 1024px) {
    .mobile-menu {
      display: flex;
    }
    .main-menu {
      display: none;
    }
  }

  @media only screen and (min-width: 1024px) {
    .mobile-menu {
      display: none;
    }
    .main-menu {
      display: flex;
    }
  }

  .swap-button {
    @media only screen and (max-width: 883px) {
      display: none;
    }
  }
`;

const NavWrapper = styled(Wrapper)`
  align-items: center;
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  height: 50px;
`;

const SocialBannerSC = styled.div`
  width: 100%;
  border-bottom: ${(props) =>
    !props.inverted
      ? `1px solid ${colorTheme.colors.neutral.neutral7}`
      : `1px solid rgba(255,255,255,0.15)`};
  .social-banner {
    display: flex;
    height: 30px;
    justify-content: space-between;
    gap: 24px;
    align-items: center;

    .left{
      color: ${(props) =>
        !props.inverted ? `${colorTheme.colors.neutral.black}` : "white"};
      cursor:pointer;
      transition:0.3s;
      opacity:0.5;
      :hover{
        opacity:1;
      }
    }

    .right {
      display: flex;
      gap: 1rem;
    }

    .social-text {
      color: white;
      @media only screen and (max-width: 772px) {
        display: none;
      }
    }

    .social-button {
      display: flex;
      height: 30px;
      align-items: center;
      border-radius: 999px;

      color: white;
      font-weight: 500;
      padding: 0rem 1rem;
      transition: 0.3s;

      @media only screen and (max-width: 772px) {
        width:30px;
        height:30px;
        padding:0px;

        img{
          margin:auto;
        }
      }

      &.twitter{
        background-color:#1da1f2;
      }

      &.discord{
        background-color:#7289da;

      }

      img {
        height: 15px;
        margin-right: 0.35rem;
      }

      :hover {
        opacity: 0.8;
      }
    }

`;

const SocialButtonSC = styled.a`
  height: 30px;
  width: 30px;
  background: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  transition: 0.3s;
  opacity: 0.3;
  &:hover {
    opacity: 1;
    background: none;
  }

  img {
    padding: 7.5px;
  }

  svg {
    border: 1px solid
      ${(props) =>
        !props.inverted ? `${colorTheme.colors.neutral.black}` : "white"};
  }
`;

const LocaleBtn = styled.button`
  margin-left: 8px;
  margin-right: 8px;
  padding: 8px 24px;
  position: relative;
  background: none;
  outline: none;
  border 1px solid rgba(255, 255, 255, 0.5);
  color:white;
  border-radius: 950px;
  cursor:pointer;

  &:hover{
    border 1px solid white;
    background: rgba(255, 255, 255, 0.05);
  }
`;
