import Image from "next/image";
import React from "react";
import styled from "styled-components";

export default function Button({
  disabled,
  onClick,
  white,
  inverted,
  buttonText,
  loading,
  ...btnProps
}) {
  return (
    <StyledButton
      disabled={disabled}
      onClick={onClick}
      white={white}
      inverted={inverted}
      {...btnProps}
    >
      {loading ? (
        <>
          <Image
            src="/assets/svg/spinner.svg"
            width={50}
            height={50}
            className="spinner-icon"
            alt="spinner"
          />
        </>
      ) : (
        buttonText
      )}
    </StyledButton>
  );
}

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: ${(props) =>
    props.white
      ? props.inverted
        ? "white"
        : "black"
      : "rgba(36, 138, 255, 1)"};
  outline: none;
  border: 1px solid
    ${(props) =>
      props.white
        ? props.inverted
          ? "white"
          : "black"
        : "rgba(36, 138, 255, 1)"};
  padding: ${(props) => (props.nav ? "6px 16px" : "8px 16px")};
  width: 100%;
  border-radius: 999px;
  color: ${(props) =>
    props.white ? (props.inverted ? "black" : "white") : "white"};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  width: 100%;
  font-size: ${(props) => (props.nav ? "16px" : "20px")};
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: ${(props) =>
      props.white
        ? props.inverted
          ? "rgba(255,255,255,0.85)"
          : "rgba(0,0,0,0.85)"
        : "rgba(33, 124, 229, 0.85)"};
    border: 1px solid
      ${(props) =>
        props.white
          ? props.inverted
            ? "rgba(255,255,255,0.85)"
            : "rgba(0,0,0,0.85)"
          : "rgba(33, 124, 229, 0.85)"};
  }

  .spinner-icon {
    max-height: 35px;
  }

  .sub {
    max-height: 50px;
  }

  :disabled {
    background-color: ${(props) =>
      props.white
        ? props.inverted
          ? "rgba(255,255,255,1)"
          : "rgba(0,0,0,0.85)"
        : "rgba(36, 138, 255, 1)"};
    border: 1px solid
      ${(props) =>
        props.white
          ? props.inverted
            ? "rgba(255,255,255,1)"
            : "rgba(0,0,0,0.85)"
          : "rgba(36, 138, 255, 1)"};
  }

  @media only screen and (max-width: 1024px) {
    font-size: 16px;
    max-height: 40px;
    &.login-button {
      padding: 8px 16px;
    }
    .spinner-icon {
      max-height: 20px;
    }
  }
`;
