import Image from "next/image";
import React from "react";
import styled from "styled-components";
import Link from "next/link";

import Text from "../../ui/Text";
import Wrapper from "../../ui/Wrapper";
import twitterLogo from "../../../public/assets/svg/social/Twitter.svg";
import logo from "../../../public/assets/svg/overline-black.svg";
import whiteLogo from "../../../public/assets/svg/logo-white.svg";
import useAffiliateUrl from "../../../hooks/useAffiliateUrl";

const general = [
  {
    title: "About",
    link: "/about",
  },
  {
    title: "FAQ",
    link: "/faq",
  },
  {
    title: "Maintainers",
    link: "/contact",
  },
  {
    title: "Contact",
    link: "/contact",
  },
  // {
  //   title: "Careers",
  //   link: "/careers",
  // },
];

const technology = [
  // { title: "Multichain", link: "/multichain" },
  { title: "Hardware", link: "/hardware" },
  { title: "Applications", link: "/applications" },
  { title: "Algorithm", link: "/algorithm" },
  { title: "Whitepaper", link: "/whitepaper" },
  { title: "Coins", link: "/coins" },
];

const community = [
  { title: "Community", link: "https://discord.gg" },
  { title: "Documentation", link: "https://docs.overline.network" },
  { title: "Twitter", link: "https://twitter.com/overlinenetwork" },
  { title: "Blog", link: "https://blog.overline.network" },
  { title: "Brand Assets", link: "/brand" },
  { title: "Press Kit", link: "/presskit" },
];

const social = [
  {
    alt: "Twitter",
    src: twitterLogo,
    link: "https://twitter.com/overlinenetwork",
  },
  {
    alt: "Discord",
    src: twitterLogo,
    link: "https://twitter.com/overlinenetwork",
  },
  {
    alt: "Github",
    src: twitterLogo,
    link: "https://twitter.com/overlinenetwork",
  },
  {
    alt: "YouTube",
    src: twitterLogo,
    link: "https://twitter.com/overlinenetwork",
  },
  {
    alt: "Twitter",
    src: twitterLogo,
    link: "https://twitter.com/overlinenetwork",
  },
  {
    alt: "Instagram",
    src: twitterLogo,
    link: "https://twitter.com/overlinenetwork",
  },
];

const lowerLinks = [
  {
    title: `© ${new Date().getFullYear()} American Centurion Group`,
    link: "https://amcegr.com",
  },
  // {
  //   title: "Privacy Policy",
  //   link: "/privacy",
  // },
  {
    title: "Terms of Service",
    link: "https://docs.overline.network/docs/amcegr-terms-and-conditions",
  },
];

export default function Footer({ inverted, white }) {
  const affiliateToken = useAffiliateUrl();

  return (
    // <ColorSection gray={true}>
    <div
      style={{
        backgroundColor: inverted
          ? "rgba(18, 22, 25, 1)"
          : white
          ? "white"
          : "rgba(18, 22, 25, 0.05)",
      }}
    >
      <Wrapper>
        <LowerContainer inverted={inverted}>
          <Link href={`#${affiliateToken}`} passHref>
            <Image
              height={20}
              src={inverted ? whiteLogo : logo}
              alt="Overline Logo"
            />
          </Link>

          <div className="lower-links">
            {lowerLinks.map((item, index) => (
              <Link href={`${item.link}${affiliateToken}`} key={index} passHref>
                {item.title}
              </Link>
            ))}
          </div>
        </LowerContainer>
      </Wrapper>
    </div>
    // </ColorSection>
  );
}

const ContentContainer = styled.div`
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 50px 0px 100px 0px;
`;

const SocialContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Circle = styled.div`
  border-radius: 9999px;
  border: 1px solid black;
  padding: 10px;
  height: 50px;
  width: 50px;
  display: flex;
  :hover {
    opacity: 0.6;
  }
`;

const LowerContainer = styled.div`
  display: flex;
  text-align: left;
  padding: 8px 0px;
  justify-content: space-between;

  span {
    img {
      height: 20px !important;
      width: auto !important;
      min-width: 0px !important;
      min-height: 0px !important;
      margin: 0px !important;
    }
  }

  .lower-links {
    display: flex;
    justify-content: space-evenly;
    gap: 24px;
    color: ${(props) => (props.inverted ? "white" : "black")} !important;
    font-size: 0.8rem;
    margin: 0px;
    align-items: center;

    @media (max-width: 768px) {
      font-size: 0.5rem;
    }

    a:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;
